



































































































































































































































































































































import ViewModel from '@/views/company/Download.ts';
export default ViewModel;
