import Vue from 'vue';

interface FormField {
	label: string;
	value: any;
}

interface Form {
	username: FormField;
	apartment: FormField;
	phone: FormField;
	email: FormField;
}

export default Vue.extend({
	data () {
		return {
			dialog_ch: false,
			ch_in_dialog:false,
			dialog_crew: false,
			dialog_admin: false,
			crew_in_dialog: false,
			admin_in_dialog: false,
			check: ['white'],
			dialog: false,
			dialog_video: true,
			form: {
				username: {
					label: "성함",
					value: null
				},
				apartment: {
					label: "회사명",
					value: null
				},
				phone: {
					label: "전화번호",
					value: null
				},
				email: {
					label: "이메일",
					value: null
				},
			} as Form
		}
	},
	methods:
	{
		HANDLE_SEND_SLACK(key: any) {
			if (!this.isPrivacyAgreed()) return;
			if (!this.areRequiredFieldsFilled()) return;

			const content = this.createContentMessage(key);
			this.sendSlackMessage(content);
		},

		isPrivacyAgreed(): boolean {
			if (this.check.length < 2) {
				this.$toasted.show("개인정보 수집이용에 동의해주세요.");
				return false;
			}
			return true;
		},

		areRequiredFieldsFilled(): boolean {
			const requiredFields: (keyof Form)[] = ['username', 'apartment', 'email'];
			for (const key of requiredFields) {
				if (this.form[key].value === null) {
					this.$toasted.show(`${this.form[key].label}을(를) 작성해 주세요.`);
					return false;
				}
			}
			return true;
		},

		createContentMessage(type: string): string {
			const contentArr = [`📢 홈페이지-${type} 신청`];

			Object.keys(this.form).forEach(key => {
				const typedKey = key as keyof Form;
				const value = this.form[typedKey].value;
				const label = this.form[typedKey].label;

				contentArr.push(this._.sprintf("%s: %s", [label, value]));
			});

			return contentArr.join('\n');
		},

		resetFormValues() {
			Object.keys(this.form).forEach(key => {
				const typedKey = key as keyof Form;
				this.form[typedKey].value = null;
			});
			this.check = ['white'];
		},

		sendSlackMessage(content: string) {
			const payload = JSON.stringify({ content });
			this.$aws.lambda.invoke(
				process.env.VUE_APP_AWS_LAMBDA_SLACK_SEND,
				"RequestResponse",
				payload
			).then( res => {
				this.resetFormValues();
				this.ch_in_dialog = true;
			});
		}
	}
});
